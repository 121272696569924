<template>
  <div>
    <profile-loading v-if="loading"/>
    <div class="profile">
      <div class="wrapper">
        <div class="nft" v-if="nftLists && nftLists.length">
          <h2 class="nft-title">User NFT</h2>
          <div class="nft-cont some">
            <div class="nft-item" v-for="(nft, index) in nftLists" :key="index">
              <img :src="nft.image_url" alt="1 YEAR Auction">
              <div class="nft-item__info">
                <div class="nft-item__header">
                  <div class="nft-item__title">{{nft.name}}</div>
                  <div class="nft-item__season" :class="nft.attributes[0].value.toLowerCase()">{{nft.attributes[0].value}}</div>
                </div>
<!--                <div class="nft-item__row">-->
<!--                  <div class="nft-item__name">bought for</div>-->
<!--                  <div class="nft-item__value">0.11 xtz</div>-->
<!--                </div>-->
                <div class="nft-item__row">
                  <div class="nft-item__name" v-if="nft.address">{{ $t("main.winner") }}</div>
                  <div class="nft-item__value" v-if="nft.address">{{nft.address.substr(0,4)}}...{{nft.address.substr(nft.address.length - 7)}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="history">
          <div class="history-header" v-if="activeAuction">
            <h2 class="history-header__title">{{ $t('main.bids_history') }}</h2>
            <router-link :to="{ name: 'Auction', params: {years: activeAuction} }" class="history-header__btn">{{ $t('main.make_new_bid') }}</router-link>
          </div>
          <div class="history-cont">
            <div class="history-cont__scroll"> <!--empty class if 0 bids-->
              <div class="history-cont__header justify-between">
                <div class="history-cont__col">{{ $t("table.date") }}</div>
                <div class="history-cont__col">{{ $t("table.auction") }}</div>
                <div class="history-cont__col">{{ $t("table.season") }}</div>
                <!--              <div class="history-cont__col">{{ $t("table.rank") }}</div>-->
                <div class="history-cont__col">{{ $t("table.bid") }}</div>
                <div class="history-cont__col">{{ $t("table.status") }}</div>
              </div>
              <div class="history-cont__items">
                <div class="history-cont__item justify-between" v-for="(transaction, index) in userTransactions" :key="index">
                  <div class="history-cont__col" v-if="checkDate(transaction.value.ts)">{{checkDate(transaction.value.ts)}}</div>
                  <div class="history-cont__col" v-else>{{ transaction.value.ts | moment("from", "now")  }}</div>
                  <div class="history-cont__col">{{ transaction.year }} {{$t("table.year")}}</div>
                  <div class="history-cont__col"><p>{{ $t("table.season") }}</p>{{seasonConverter(transaction.value.season)}}</div>
                  <!--                <div class="history-cont__col fault"><p>{{ $t("table.rank") }}</p>1 <span>(-15)</span></div>-->
                  <div class="history-cont__col"><p>{{ $t("table.bid") }}</p>{{ transaction.value.amount / 1000000}} xtz</div>
                  <div class="history-cont__col" :class="transaction.statusWin && !transaction.active ? 'completed' : transaction.statusWin ? 'active' : transaction.active && !transaction.auctionFinished ? 'active' : 'fault'">{{transaction.statusWin && !transaction.active ? 'Completed' : transaction.statusWin && transaction.auctionFinished ? 'Win' : transaction.active && !transaction.auctionFinished ? 'Active' : 'Fault'}}</div>
                </div>
              </div>
            </div>
          </div>
            <router-link :to="{ name: 'Auction', params: {years: activeAuction} }" class="history-btn__mob">{{ $t('main.make_new_bid') }}</router-link>
        </div>
      </div>
      <WithdrawalModal :data="userData" :currentAuctionContract="currentAuctionContract" v-if="isModalOpen" :close="close" />
    </div>
  </div>
</template>
<script>
import WithdrawalModal from '../components/WithdrawModal.vue';
import { mapActions, mapState } from 'vuex'
import config from '../config'
import moment from "moment"
import ProfileLoading from "../components/Loading/ProfileLoading";

export default {
  name: 'WalletInfo',
  components: {
    ProfileLoading,
    WithdrawalModal
  },
  computed: {
    ...mapState({
      userTransactions: state => state.auction.userTransactions,
      nftLists: state => state.auction.nftLists,
    })
  },
  created() {
    this.allAuctionsContract.push(config['contract_1years']);
    this.allAuctionsContract.push(config['contract_10years']);
    this.allAuctionsContract.push(config['contract_100years']);
  },
  mounted() {
    const address = this.$route.params.address
    this.getCurrentAuctionUserTransactions(address)
    this.getNft({address: address})
  },
  data () {
    return {
      loading: true,
      userData: '',
      currentAuctionContract: '',
      auctionFinished: false,
      isModalOpen: false,
      activeBinsCount: 0,
      completedBinsCount: 0,
      winBinsCount: 0,
      faultBinsCount: 0,
      outRankBinsCount: 0,
      binsCount: 0,
      activeAuction: null,
      allAuctionsContract: []
    };
  },
  methods: {
    ...mapActions({
      getUserTransactions: 'auction/getUserTransactions',
      getNft: 'auction/getNft',
      takeNftResolve: 'authWallet/takeNftResolve'
    }),
    checkDate(date) {
      moment.fn.fromNow = () => {
        return moment().diff(this, 'day');
      }
      if (moment(date).fromNow() > 30) {
        return moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY.MM.DD')
      } else {
        return false
      }

    },
    isActiveAuction(year) {
      let countdown = moment(year, 'YYYY-MM-DD HH:mm:ss');
      let t = moment(new Date());
      if (countdown.diff(t) && countdown.diff(t) <= 0) {
         return false
      } else {
        return true
      }
    },
    takeNft (data) {
      const currentAuctionContract = config['contract_' + data.year + 'years'];
      this.takeNftResolve({contract: currentAuctionContract, id: data.key, amount: data.value.amount})
    },
    getCurrentAuctionUserTransactions(address) {
      if (address){
        let params = {
          userAddress: address,
        }
        this.getUserTransactions(params).then((res) => {
          res.map(item =>  {
            let date = localStorage.getItem(item.year + 'years')
            let countdown = moment(date, 'YYYY-MM-DD HH:mm:ss');
            let t = moment(new Date());
            let end = false
            if (countdown.diff(t) && countdown.diff(t) >= 0) {
              end = true
            }

            if (item.active && end && item.statusWin) {
              this.activeBinsCount ++
            }

            if (!item.statusWin && !item.active) {
              this.faultBinsCount ++
            }
            if (!item.statusWin && item.active) {
              this.outRankBinsCount ++
            }

            if (item.statusWin && !item.active && !end) {
              this.completedBinsCount ++
            }

            if (item.statusWin && item.active && !end) {
              this.winBinsCount ++
            } else {
              item.status = false
            }
          })

          this.binsCount = res.length

        }).finally(() => {
          this.loading = false
        });
      }
    },
    close () {
      this.isModalOpen = false;
      this.userData = {}
      this.currentAuctionContract = ''
    },
    openModal (data) {
      this.userData = data
      this.currentAuctionContract = config['contract_' + data.year + 'years'];
      this.isModalOpen = true;
    },
    seasonConverter (season) {
      if (season === '1') {
        return 'Winter'
      } else if (season === '2') {
        return 'Spring'
      } else if (season === '3') {
        return 'Summer'
      } else {
        return 'Autumn'
      }
    },
    increaseBid (year) {
      this.$router.push({
        name: 'Auction',
        params: {
          years: year
        }
      })
    }
  },
  filters: {
    yearsByContract: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
}
</script>
<style scoped lang="scss">
.justify-between {
  justify-content: space-between;
}

.profile {
  overflow: hidden;
  .wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}
.info {
  margin-top: 50px;
  @media (max-width: 768px) {
    margin-top: 40px;
  }
  &-title {
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    color: #333333;
  }
  &-cont {
    margin-top: 16px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    /*max-width: 370px;*/
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    color: #333333;
    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px;
      @media (max-width: 375px) {
        padding: 12px;
      }
    }
    &__overall {
      display: flex;
      align-items: center;
      img {
        margin-right: 12px;
      }
    }
    &__made {
      span {
        font-weight: bold;
      }
    }
    &__bids {
      display: grid;
      grid-template-columns: repeat(5, min-content);
      grid-gap: 20px;
      border-top: 1px solid #DFDFDF;
      padding: 18px;
      @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        padding: 12px;
        justify-items: start;
      }
    }
    &__bid {
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      @media (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
    &__status {
      margin-right: 9px;
      @media (max-width: 375px) {
        margin-right: 4px;
      }
      &.active {
        color: #46C07C;
      }
      &.fault {
        color: #FF0000;
      }
    }
  }
  &-details {
    margin-top: 14px;
    font-size: 18px;
    line-height: 24px;
    color: #333333;
  }
}
.nft {
  margin-top: 60px;
  width: 100%;
  @media (max-width: 768px) {
    margin-top: 50px;
  }
  &-title {
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    color: #333333;
  }
  &-cont {
    margin-top: 20px;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      justify-content: center;
    }
    &.some {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 45px;
      @media (max-width: 992px) {
        grid-template-columns: 340px 340px;
        grid-gap: 18px;
        justify-content: center;
      }
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        .nft-item {
          display: none;
        }
      }
    }
  }
  &-item {
    flex-basis: 31%;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    overflow: hidden;
    @media (max-width: 992px) {
      flex-basis: 45%;
    }
    @media (max-width: 768px) {
      max-width: 370px;
      width: 100%;
      flex-basis: 100%;
    }
    img {
      width: 100%;
      height: 305px;
      object-fit: contain;
      background: #7D888A;
      @media (max-width: 576px) {
        height: 290px;
      }
    }
    &__info {
      padding: 20px 18px;
      font-size: 18px;
      line-height: 26px;
      color: #000000;
      @media (max-width: 768px) {
        padding: 16px;
      }
    }
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__season {
      padding: 4px 16px;
      border-radius: 10px;
      &.autumn {
        color: #ED7D37;
        background: rgba(237, 125, 55, 0.1);
      }
      &.spring {
        color: #B6DA06;
        background: rgba(182, 218, 6, 0.1);
      }
      &.winter {
        color: #086DF9;
        background: rgba(8, 109, 249, 0.1);
      }
      &.summer {
        color: #22FF00;
        background: rgba(34, 255, 0, 0.1);
      }
    }
    &__title {
      font-weight: bold;
    }
    &__row {
      margin-top: 16px;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        margin-top: 12px;
      }
    }
    &__name {
      flex-basis: 85px;
      margin-right: 12px;
    }
    &__value {
      font-weight: bold;
    }
  }
  &-auction {
    padding-left: 150px;
    position: relative;
    width: calc(100% - 370px);
    @media (max-width: 992px) {
      padding-left: 50px;
      width: 60%;
    }
    @media (max-width: 768px) {
      display: none;
    }
    img {
      position: absolute;
      right: -100px;
      bottom: -50px;
      z-index: -1;
    }
    &__title {
      font-weight: bold;
      font-size: 45px;
      line-height: 55px;
      color: #C2C2C2;
      margin-bottom: 22px;
      max-width: 320px;
      @media (max-width: 992px) {
        font-size: 40px;
        line-height: 50px;
      }
    }
    &__link {
      color: #ED7D37;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: #ED7D37;
      text-decoration: none;
      &:hover {
        color: #E07735;
      }
    }
  }
}
.splide {
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
}
.history {
  margin: 60px 0 85px;
  width: 100%;
  @media (max-width: 768px) {
    margin: 50px 0 70px;
  }
  &-btn__mob {
    background: #ED7D37;
    border-radius: 5px;
    padding: 12px 24px;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    font-weight: bold;
    text-decoration: none;
    margin: 0 auto;
    display: none;
    margin-top: 20px;
    &:hover {
      background: #E07735;
    }
    @media (max-width: 768px) {
      display: table;
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title {
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
      color: #333333;
    }
    &__btn {
      background: #ED7D37;
      border-radius: 5px;
      padding: 12px 24px;
      font-size: 20px;
      line-height: 23px;
      color: #FFFFFF;
      font-weight: bold;
      text-decoration: none;
      &:hover {
        background: #E07735;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  &-cont {
    margin-top: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    width: 100%;
    height: 420px;
    background: #fff;
    padding: 12px 30px 10px;
    overflow-x: auto;
    overflow-y: visible;
    @media (max-width: 768px) {
      min-height: 80px;
      height: auto;
      padding: 0 7px 10px;
    }
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 2.5px;
      background: #EAEAEA;
    }
    &::-webkit-scrollbar-thumb {
      background: #ED7D37;
      border-radius: 2.5px;
    }
    &__scroll {
      min-width: 1140px;
      @media (max-width: 768px) {
        min-width: 1px;
      }
      &.empty {
        height: 100%;
        min-width: 1px;
        @media (max-width: 768px) {
          height: 60px;
          display: flex;
          align-items: center;
        }
        .history-cont__header {
          .history-cont__col {
            flex-basis: 15%;
          }
        }
      }
    }
    &__empty {
      width: 100%;
      height: calc(100% - 37px);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      line-height: 21px;
      color: #B1B1B1;
    }
    &__header {
      white-space: nowrap;
      display: flex;
      //justify-content: space-between;
      padding-left: 12px;
      padding-right: 20px;
      padding-bottom: 12px;
      border-bottom: 1px solid #DFDFDF;
      .auction-history__col {
        color: #727A89;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
    &__col {
      font-size: 18px;
      line-height: 24px;
      color: #333333;
      position: relative;
      white-space: nowrap;
      span {
        position: absolute;
        top: 0;
        left: 50px;
        @media (max-width: 768px) {
          position: static;
          margin-left: 8px;
        }
      }
      p {
        display: none;
        color: #727A89;
        margin-right: 10px;
        font-size: 12px;
        @media (max-width: 768px) {
          display: block;
        }
      }
      @media (max-width: 768px) {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 20px;
      }
      &:nth-child(1) {
        flex-basis: min(210px);
        @media (max-width: 768px) {
          order: 2;
          font-size: 14px;
          line-height: 20px;
          color: #727A89;
          margin-left: auto;
        }
      }
      &:nth-child(2) {
        flex-basis: min(120px);
        @media (max-width: 768px) {
          order: 1;
          font-weight: bold;
        }
      }
      &:nth-child(3) {
        flex-basis: min(120px);
        p {
          width: 55px;
        }
        @media (max-width: 768px) {
          order: 6;
        }
      }
      &:nth-child(4) {
        flex-basis: min(110px);
        p {
          width: 55px;
        }
        @media (max-width: 768px) {
          order: 4;
        }
      }
      &:nth-child(5) {
        flex-basis: min(200px);
        @media (max-width: 768px) {
          order: 5;
        }
      }
      &:nth-child(6) {
        flex-basis: min(120px);
        @media (max-width: 768px) {
          order: 3;
          grid-column: span 2 / auto;
        }
      }
      &:nth-child(7) {
        flex-basis: min(265px);
        @media (max-width: 768px) {
          order: 7;
          grid-column: span 2 / auto;
          justify-content: center;
        }
      }
      &.active {
        color: #46C07C;
      }
      &.fault {
        color: #FF0000;
      }
      &.change {
        span {
          color: #ED7D37;
        }
      }
    }
    &__items {
      overflow-y: auto;
      max-height: 346px;
      height: 100%;
      margin-top: 10px;
      padding-right: 20px;
      @media (max-width: 768px) {
        max-height: 500px;
      }
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 1px;
        background: #EAEAEA;
      }
      &::-webkit-scrollbar-thumb {
        background: #ED7D37;
        border-radius: 1px;
      }
      @media (max-width: 768px) {
        padding-right: 8px;
      }
    }
    &__item {
      white-space: nowrap;
      display: flex;
      align-items: center;
      padding: 0 0 0 12px;
      height: 52px;
      //border: 1px solid transparent;
      border-bottom: 1px solid #DFDFDF;
      cursor: default;
      @media (max-width: 768px) {
        height: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 8px;
        padding: 12px 8px;
      }
    }
    &__btn {
      padding: 5px 16px;
      border-radius: 5px;
      font-size: 18px;
      line-height: 24px;
      transition: .35s;
      &.increase {
        background: rgba(237, 125, 55, 0.1);
        border-radius: 5px;
        border: 1px solid transparent;
        color: #ED7D37;
        margin-right: 25px;
        &:hover {
          border: 1px solid #ED7D37;
        }
      }
      &.withdraw {
        color: #333333;
        &:hover {
          color: #ED7D37;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .history-btn__mob {
    display: none;
  }
}
</style>
