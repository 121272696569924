var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('profile-loading') : _vm._e(), _c('div', {
    staticClass: "profile"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_vm.nftLists && _vm.nftLists.length ? _c('div', {
    staticClass: "nft"
  }, [_c('h2', {
    staticClass: "nft-title"
  }, [_vm._v("User NFT")]), _c('div', {
    staticClass: "nft-cont some"
  }, _vm._l(_vm.nftLists, function (nft, index) {
    return _c('div', {
      key: index,
      staticClass: "nft-item"
    }, [_c('img', {
      attrs: {
        "src": nft.image_url,
        "alt": "1 YEAR Auction"
      }
    }), _c('div', {
      staticClass: "nft-item__info"
    }, [_c('div', {
      staticClass: "nft-item__header"
    }, [_c('div', {
      staticClass: "nft-item__title"
    }, [_vm._v(_vm._s(nft.name))]), _c('div', {
      staticClass: "nft-item__season",
      class: nft.attributes[0].value.toLowerCase()
    }, [_vm._v(_vm._s(nft.attributes[0].value))])]), _c('div', {
      staticClass: "nft-item__row"
    }, [nft.address ? _c('div', {
      staticClass: "nft-item__name"
    }, [_vm._v(_vm._s(_vm.$t("main.winner")))]) : _vm._e(), nft.address ? _c('div', {
      staticClass: "nft-item__value"
    }, [_vm._v(_vm._s(nft.address.substr(0, 4)) + "..." + _vm._s(nft.address.substr(nft.address.length - 7)))]) : _vm._e()])])]);
  }), 0)]) : _vm._e(), _c('div', {
    staticClass: "history"
  }, [_vm.activeAuction ? _c('div', {
    staticClass: "history-header"
  }, [_c('h2', {
    staticClass: "history-header__title"
  }, [_vm._v(_vm._s(_vm.$t('main.bids_history')))]), _c('router-link', {
    staticClass: "history-header__btn",
    attrs: {
      "to": {
        name: 'Auction',
        params: {
          years: _vm.activeAuction
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('main.make_new_bid')))])], 1) : _vm._e(), _c('div', {
    staticClass: "history-cont"
  }, [_c('div', {
    staticClass: "history-cont__scroll"
  }, [_c('div', {
    staticClass: "history-cont__header justify-between"
  }, [_c('div', {
    staticClass: "history-cont__col"
  }, [_vm._v(_vm._s(_vm.$t("table.date")))]), _c('div', {
    staticClass: "history-cont__col"
  }, [_vm._v(_vm._s(_vm.$t("table.auction")))]), _c('div', {
    staticClass: "history-cont__col"
  }, [_vm._v(_vm._s(_vm.$t("table.season")))]), _c('div', {
    staticClass: "history-cont__col"
  }, [_vm._v(_vm._s(_vm.$t("table.bid")))]), _c('div', {
    staticClass: "history-cont__col"
  }, [_vm._v(_vm._s(_vm.$t("table.status")))])]), _c('div', {
    staticClass: "history-cont__items"
  }, _vm._l(_vm.userTransactions, function (transaction, index) {
    return _c('div', {
      key: index,
      staticClass: "history-cont__item justify-between"
    }, [_vm.checkDate(transaction.value.ts) ? _c('div', {
      staticClass: "history-cont__col"
    }, [_vm._v(_vm._s(_vm.checkDate(transaction.value.ts)))]) : _c('div', {
      staticClass: "history-cont__col"
    }, [_vm._v(_vm._s(_vm._f("moment")(transaction.value.ts, "from", "now")))]), _c('div', {
      staticClass: "history-cont__col"
    }, [_vm._v(_vm._s(transaction.year) + " " + _vm._s(_vm.$t("table.year")))]), _c('div', {
      staticClass: "history-cont__col"
    }, [_c('p', [_vm._v(_vm._s(_vm.$t("table.season")))]), _vm._v(_vm._s(_vm.seasonConverter(transaction.value.season)))]), _c('div', {
      staticClass: "history-cont__col"
    }, [_c('p', [_vm._v(_vm._s(_vm.$t("table.bid")))]), _vm._v(_vm._s(transaction.value.amount / 1000000) + " xtz")]), _c('div', {
      staticClass: "history-cont__col",
      class: transaction.statusWin && !transaction.active ? 'completed' : transaction.statusWin ? 'active' : transaction.active && !transaction.auctionFinished ? 'active' : 'fault'
    }, [_vm._v(_vm._s(transaction.statusWin && !transaction.active ? 'Completed' : transaction.statusWin && transaction.auctionFinished ? 'Win' : transaction.active && !transaction.auctionFinished ? 'Active' : 'Fault'))])]);
  }), 0)])]), _c('router-link', {
    staticClass: "history-btn__mob",
    attrs: {
      "to": {
        name: 'Auction',
        params: {
          years: _vm.activeAuction
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('main.make_new_bid')))])], 1)]), _vm.isModalOpen ? _c('WithdrawalModal', {
    attrs: {
      "data": _vm.userData,
      "currentAuctionContract": _vm.currentAuctionContract,
      "close": _vm.close
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }